import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, LinearProgress } from '@mui/material';
import KeddyHeader from '../../KeddyHeader/KeddyHeader';
import { linkSpotifyUserProperties } from '../../../services/Auth';

function SpotifyCallback() {
    const navigate = useNavigate();
    const location = useLocation(); 

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        const state = queryParams.get('state');
        if (code && state) {
          // Execute the linking function with the code and state
          linkSpotifyUserProperties(code, state).then((response) => {
            if (response.status === 200) {
              navigate('/dashboard');
            } else {
              const code = response?.data?.code;
              navigate('/error', { search: `?code=${code}`});            
            }
          }).catch(err => {
            console.error("Unexpected error occurred:", err);
            // Handle error and possibly navigate to an error page
            navigate('/error');
          });
        } else {
            navigate('/error', { search: "?code=code_state_not_provided"}); 
        }
      }, [location, navigate]);

    return (
        <div className='profile__complete-profile-container'>
            <div className='profile__content'>
                <div style={{ marginBottom: 35 }}>
                    <KeddyHeader />
                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography variant='h4'>Linking your Spotify account</Typography>
                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 35 }}>
                    <LinearProgress style={{ width: "100%", height: "10px"}} color="inherit" />
                </div>
            </div>
        </div>
    );
}

export default SpotifyCallback;