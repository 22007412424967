// src/App.js
import React, { useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import LoginPage from './components/Pages/LoginPage/LoginPage';
import Error from './components/Pages/Error/Error';
import { AppBar, Tabs, Tab, Box, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutline from '@mui/icons-material/PersonOutline';

import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';



import './App.css';
import Profile from './components/Pages/Profile/Profile';
import AuthProvider from './AuthProvider';
import SpotifyCallback from './components/Pages/SpotifyCallback/SpotifyCallback';
import RecommendationsRouter from './components/Pages/Recomendations/RecommendationsRouter';
import Recommend from './components/Pages/Recomendations/Recommend/Recommend';
import RecommendationResults from './components/Pages/Recomendations/RecommendationResults/RecommendationResults';
import RecommendationResult from './components/Pages/Recomendations/RecommendationResult/RecommendationResult';


function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(false); // Set the initial value
  const [accountAnchorEl, setaccountAnchorEl] = React.useState(null);
  const accountPopupOpen = Boolean(accountAnchorEl);

  const menuItemSelected = (value) => {
    switch (value) {
      case 'settings':
        break;
      case 'profile':
        navigate('/profile');
        break;
      case 'logout':
        navigate('/login');
        break;
      default:
        break;
    }
    setaccountAnchorEl(null);
  };


  useEffect(() => {
    if (location.pathname.startsWith('/recommendations/recommend')) {
      setValue('recommend'); // Select the "Recommendations" tab
    } else if (location.pathname.startsWith('/recommendations/results')) {
      setValue('recommendationResults'); // Select the "Dashboard" tab
    } else {
      setValue(false); // No tab selected for other paths
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Navigate to the selected tab's route
    switch (newValue) {
      case 'recommend':
        navigate('/recommendations/recommend');
        break;
      case 'recommendationResults':
        navigate('/recommendations/results');
        break;
      default:
        navigate('/error');
        break;
    }
  };

  return (
    <div className="App">{value &&
      (<AppBar position="fixed" sx={{
        height: { xs: '48px', sm: '48px' }, // 56px on small screens (xs), 64px on larger screens (sm and up)
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '5px',
        paddingRight: '15px'
      }} >
        <Tabs sx={{ flexGrow: 1 }} value={value} textColor="inherit" indicatorColor="secondary" onChange={handleChange}>
          <Tab label="Recommendations" value="recommend" />
          <Tab label="Recommendation Results" value="recommendationResults" />
        </Tabs>
        <IconButton
          id="account-button"
          aria-controls={accountPopupOpen ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={accountPopupOpen ? 'true' : undefined}
          onClick={(event) => {
            setaccountAnchorEl(event.currentTarget);
          }}
          style={{ padding: "0px" }}>
          <PersonIcon sx={{ color: 'white', width: "35px", height: "35px" }} />
        </IconButton>
        <Menu
          id="account-menu"
          anchorEl={accountAnchorEl}
          open={accountPopupOpen}
          onClose={(value) => { setaccountAnchorEl(null) }}
          MenuListProps={{
            'aria-labelledby': 'account-button',
          }}
        >
          <MenuItem onClick={() => { menuItemSelected("profile") }}>
            <ListItemIcon>
              <PersonOutline fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem onClick={() => { menuItemSelected("settings") }}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={() => { menuItemSelected("logout") }}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </AppBar>)}
      <Box sx={{ paddingTop: { xs: '48px', sm: '48px' } }}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/spotify-callback" element={<SpotifyCallback />} />
          <Route path="/recommendations" element={<RecommendationsRouter />}>
            <Route path="" element={<Navigate to="recommend" />} />
            <Route path="recommend" element={<Recommend />} />
            <Route path="results" element={<RecommendationResults />} />
            <Route path="result/:resultId" element={<RecommendationResult />} />
          </Route>
          <Route path="/profile" element={<Profile />} />
          <Route path="/" element={<Navigate to="/recommendations" />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </Box>
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <AuthProvider>
      </AuthProvider>
      <App />
    </Router>
  );
}
export default AppWithRouter;
