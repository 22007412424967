import axiosInstance from "./axiosInstance";
import Emotion from "../models/Emotion";
import EmotionTrackMapping from "../models/EmotionTrackMapping";
import RecommendationDto from "../models/RecommendationDto";
import RecommendationResult from "../models/RecommendationResult";

const getEmotions = async () => {
    try {
        const response = await axiosInstance.get(`/api/recommendations/emotions`);
        const emotions = response.data.map(Emotion.fromJson);
        return emotions
    } catch (error) {
        console.error('Error fetching emotions:', error);
        return null;
    }
};


const getEmotionTrackMappings = async (withAllEmotions = true) => {
    try {
        const response = await axiosInstance.get(`/api/recommendations/emotion-track-mappings`, {
            params: {
                withAllEmotions: withAllEmotions
            }
        });
        const emotionTrackMappingList = response.data.map(EmotionTrackMapping.fromJSON);
        return emotionTrackMappingList
    } catch (error) {
        console.error('Error fetching emotionTrackMappingList:', error);
        return null;
    }
};

const getRecommendationByValue = async (valence, arousal) => {
    try {
        const response = await axiosInstance.get(`/api/recommendations/by-value`, {
            params: {
                valence: valence,
                arousal: arousal
            }
        });
        return response.data
    } catch (error) {
        console.error('Error fetching emotions:', error);
        return null;
    }
};

const getRecommendationDtoByResultId = async (resultId) => {
    try {
        const response = await axiosInstance.get(`/api/recommendations/recommendation/${resultId}`);
        const recommendations = RecommendationDto.fromJSON(response.data);
        return recommendations
    } catch (error) {
        console.error('Error fetching recommendation by result id:', error);
        return null;
    }
};

const getRecommendationResults = async () => {
    try {
        const response = await axiosInstance.get(`/api/recommendations/results`);
        const results = response.data.map(RecommendationResult.fromJSON);
        return results;
    } catch (error) {
        console.error('Error fetching recommendation results:', error);
        return null;
    }
};

export {
    getEmotions,
    getEmotionTrackMappings,
    getRecommendationByValue,
    getRecommendationDtoByResultId,
    getRecommendationResults
};