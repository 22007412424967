// src/LoginPage.js
import React, { useState } from 'react';
import './LoginPage.css';
import countryCodes from 'country-codes-list'
import InputMask from 'react-input-mask';
import { Typography, TextField, Autocomplete } from '@mui/material';
import {
  loginOrRegisterUser,
  sendVerification,
  clearLoginToken
} from '../../../services/Auth';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import KeddyHeader from '../../KeddyHeader/KeddyHeader'


const countryCodeKeys = countryCodes.customList('countryCode', '+{countryCallingCode} | {countryNameEn}');
const countryCodeOptions = Object.keys(countryCodeKeys).map((value) => countryCodeKeys[value]);

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [requestErrorText, setRequestErrorText] = useState(null)
  const [formValues, setFormValues] = useState({
    phoneNumber: '',
    countryCode: null,
    verificationCode: ''
  });
  const navigate = useNavigate();

  clearLoginToken();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCountryCodeChange = (newValue) => {
    setFormValues({
      ...formValues,
      countryCode: newValue,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const numericPhoneNumber = formValues.phoneNumber == null ? "" : formValues.phoneNumber.replace(/\D/g, '');
    if (verificationSent) {
      const verificationNum = formValues.verificationCode.replace(/\D/g, '');
      if (verificationNum.length !== 6) {
        setRequestErrorText("Invalid verification code");
        return
      } else {
        const fullNumber = formValues.countryCode.split(" | ")[0] + numericPhoneNumber;
        setIsLoading(true);
        loginOrRegisterUser(fullNumber, verificationNum).then(() => {
          navigate("/profile");
        }).catch((err) => {
          setRequestErrorText(err.message)
        }).finally(() => {
          setIsLoading(false);
        });
      }
    } else {
      if (numericPhoneNumber.length !== 10) {
        setRequestErrorText("Phone is invalid");
        return;
      }

      if (formValues.countryCode == null) {
        setRequestErrorText("Country code is invalid");
        return;
      }

      const fullNumber = formValues.countryCode.split(" | ")[0] + numericPhoneNumber;

      setIsLoading(true);
      sendVerification(fullNumber).then(() => {
        setVerificationSent(true);
        setRequestErrorText(null);
      }).catch((err) => {
        setRequestErrorText(err.message)
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };
  return (
    <div className="loginpage__login-container">
      <form className="loginpage__form" onSubmit={handleSubmit}>
        <div style={{ marginBottom: 35 }}>
          <KeddyHeader />
        </div>

        <Typography variant="h5" component="h5" >
          You are just a step away from enhancing your choice of music
        </Typography>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: 35 }}>
          <div style={{ width: "40%" }}>
            <Autocomplete
              required
              size="medium"
              options={countryCodeOptions}
              disabled={verificationSent}
              getOptionLabel={(option) => option}
              value={formValues.countryCode}
              variant="standard"
              onChange={(event, newValue) => handleCountryCodeChange(newValue)}
              renderInput={(params) => <TextField {...params} label="Country Code" variant="outlined" />}
            />
          </div>
          <div style={{ width: "40%" }}>
            <InputMask
              mask="(999) 999 9999"
              value={formValues.phoneNumber}
              disabled={verificationSent}
              onChange={handleInputChange}
              maskChar="_"
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  type="tel"
                  variant="standard"
                  margin="normal"
                  label="Your phone number"
                  name="phoneNumber"
                  required
                />
              )}
            </InputMask>
          </div>

        </div>
        {verificationSent && (
          <div>
            <InputMask
              mask="999999"
              value={formValues.verificationCode}
              disabled={isLoading}
              onChange={handleInputChange}
              maskChar="_"
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  type="tel"
                  variant="standard"
                  margin="normal"
                  label="Verification Code"
                  name="verificationCode"
                  required
                />
              )}
            </InputMask>
          </div>
        )}

        <div style={{ marginTop: 35}}>
          <LoadingButton variant="contained" loading={isLoading} color="primary" type="submit" className="login-button" >{verificationSent ? "Log in" : "Send Verification Code"}</LoadingButton>
          {requestErrorText && (
            <Typography variant="body2" color="error" style={{ marginTop: 8 }}>
              {requestErrorText}
            </Typography>
          )}
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
