import './Recommend.css';
import React, { useState } from 'react';
import { TextField, Button, CircularProgress, Box } from '@mui/material';
import { getRecommendationByValue } from '../../../../services/Recommendations';
import { useNavigate } from 'react-router-dom';
const Recommend = () => {
    const [valence, setValence] = useState('');
    const [arousal, setArousal] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleValenceChange = (event) => {
        const value = parseFloat(event.target.value);
        if (value >= -1 && value <= 1) {
            setValence(event.target.value);
        }
    };

    const handleArousalChange = (event) => {
        const value = parseFloat(event.target.value);
        if (value >= -1 && value <= 1) {
            setArousal(event.target.value);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        const resultId = await getRecommendationByValue(valence, arousal);
        if (resultId) {
            // Navigate to /recommendations/results/resultId
            navigate(`/recommendations/result/${resultId}`);
        }
        setLoading(false);
    };

    return (
        <Box sx={{ maxWidth: 400, margin: 'auto', mt: 4 }}>
            <TextField
                label="Valence"
                variant="outlined"
                type="number"
                inputProps={{ min: -1, max: 1, step: "0.01" }}
                value={valence}
                onChange={handleValenceChange}
                fullWidth
                margin="normal"
            />

            <TextField
                label="Arousal"
                variant="outlined"
                type="number"
                inputProps={{ min: -1, max: 1, step: "0.01" }}
                value={arousal}
                onChange={handleArousalChange}
                fullWidth
                margin="normal"
            />

            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading || valence === '' || arousal === ''}
                fullWidth
                sx={{ mt: 2 }}
            >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
            </Button>
        </Box>
    );
};

export default Recommend;
