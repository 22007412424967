import React from 'react';
import { ListItem, ListItemText, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

// Helper function to format timestamp to DD.MM.YYYY, HH:MM
const formatTimestamp = (date) => {
    return new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }).format(date);
};

const RecommendationResultItem = ({ result }) => {
    const navigate = useNavigate(); // Initialize navigate for route navigation

    const handleClick = () => {
        navigate(`/recommendations/result/${result.id}`);
    };

    return (
        <Paper
            elevation={3}
            sx={{
                marginBottom: '8px',
                borderRadius: '8px',
                overflow: 'hidden',
                width: '100%',
                height: 'auto',
                backgroundColor: 'black',
                cursor: 'pointer', // Make cursor pointer to indicate it's clickable
                '&:hover': {
                    backgroundColor: '#1c1c1c', // Darker background on hover
                },
            }}
            onClick={handleClick} // Handle click event
        >
            <ListItem
                sx={{
                    backgroundColor: 'black', // Black background
                    color: 'white', // White text
                    padding: '16px',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    '&:hover': {
                        backgroundColor: '#1c1c1c', // Darker background on hover
                    },
                }}
            >
                {/* Title */}
                <ListItemText
                    primary={
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '1.2rem' }}>
                            {result.title || 'Untitled'}
                        </Typography>
                    }
                    secondary={
                        <Typography variant="body2" sx={{ color: '#fff9f0' }}>
                            {result.userPrompt || 'No user prompt provided'}
                        </Typography>
                    }
                />

                {/* Time Created (Formatted) */}
                <Typography variant="body2" sx={{ color: 'gray', marginTop: '8px' }}>
                    Created At: {formatTimestamp(result.timeCreated)}
                </Typography>

                {/* Valence and Arousal */}
                <Typography variant="body2" sx={{ color: 'gray', marginTop: '4px' }}>
                    Valence: {result.valence}, Arousal: {result.arousal}
                </Typography>

                {/* VibeId */}
                {result.vibeId && (
                    <Typography variant="body2" sx={{ color: 'gray', marginTop: '4px' }}>
                        Vibe ID: {result.vibeId}
                    </Typography>
                )}
            </ListItem>
        </Paper>
    );
};

export default RecommendationResultItem;
